import { useMediaQuery, useTheme } from '@deltasierra/react/components/core';
import { ShowOnSizesContext } from '../contexts';

export type ResponsiveSizesProviderProps = {
    children: React.ReactNode;
};

export function ShowOnSizesProvider({ children }: ResponsiveSizesProviderProps): JSX.Element {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    return <ShowOnSizesContext.Provider value={{ isDesktop, isMobile }}>{children}</ShowOnSizesContext.Provider>;
}

ShowOnSizesProvider.displayName = 'showOnSizesProvider';
