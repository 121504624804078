import { useShowOnSizes } from './hook';
import { ShowOnBreakpoints } from './types';

export type ShowOnProps = { breakpoint: ShowOnBreakpoints; children: JSX.Element };

export const ShowOn = ({ breakpoint, children }: ShowOnProps): JSX.Element | null => {
    const { isDesktop, isMobile } = useShowOnSizes();

    if ((breakpoint === 'xsDown' && isMobile) || (breakpoint === 'smUp' && isDesktop)) {
        return children;
    }
    return null;
};

ShowOn.displayName = 'ShowOn';
